/** @format */

import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import Video from './components/modals/Video';
// import Whatsapp from './components/modals/Whatsapp';
// import InformativeTalks from './components/modals/InformativeTalks';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import videoReducer from './features/video';
import advisoryReducer from './features/advisory';
import sideMenuReducer from './features/menu';
import bannerReducer from './features/banner';
import counselingReducer from './features/counseling';

import modalReducer from './features/modal';
import UpdateBar from './components/modals/UpdateBar';
import WhatsappMobile from './components/modals/WhatsappMobile';
import CloseInscriptions from './components/modals/CloseInscriptions';
import Advisory from './components/modals/Advisory';

const MainModal = lazy(() => import('./components/modals/MainModal'));
const Whatsapp = lazy(() => import('./components/modals/Whatsapp'));
// const InformativeTalks = lazy(() =>
//   import('./components/modals/InformativeTalks')
// );
const Video = lazy(() => import('./components/modals/Video'));

const store = configureStore({
	reducer: {
		video: videoReducer,
		counseling: counselingReducer,
		advisory: advisoryReducer,
		sideMenu: sideMenuReducer,
		banner: bannerReducer,
		modal: modalReducer,
	},
});

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
			<Suspense fallback={<div></div>}>
				<Advisory />
				<Video />
				{/* <InformativeTalks /> */}
				<Whatsapp />
				<WhatsappMobile />
				{/* <MainModal /> */}
			</Suspense>
			{/* <UpdateBar /> */}
			{/* <CloseInscriptions /> */}
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

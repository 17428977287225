import { createSlice } from '@reduxjs/toolkit';

const initialSateValue = {
	modal: false,
};

export const advisorySlice = createSlice({
	name: 'advisory',
	initialState: {
		value: initialSateValue,
	},
	reducers: {
		openAdvisory: (state, action) => {
			state.value = { modal: true };
		},
		closeAdvisory: (state, action) => {
			state.value = { modal: false };
		},
	},
});

export const { openAdvisory, closeAdvisory } = advisorySlice.actions;

export default advisorySlice.reducer;

/** @format */

import React, { useState } from 'react';
import { lazy, Suspense } from 'react';
import './App.css';
import Gallery from './components/Gallery';

// import Start from './components/Start';
// import Waves from './components/Waves';
// import Certificacion from './components/Certificacion';

const SimpleModal = lazy(() => import('./components/modals/SimpleModal'));
const Navbar = lazy(() => import('./components/Navbar'));
const SwipersHome = lazy(() => import('./components/SwipersHome'));
const Especialistas = lazy(() => import('./components/Especialistas'));
const HeaderForm = lazy(() => import('./components/HeaderForm'));
const QuienesSomos = lazy(() => import('./components/QuienesSomos'));
const MastersHome = lazy(() => import('./components/MastersHome'));
// const BenefitsHome = lazy(() => import('./components/BenefitsHome'));
const ResultadosAlumnos = lazy(() => import('./components/ResultadosAlumnos'));
const DocentesHome = lazy(() => import('./components/DocentesHome'));
const Alinazas = lazy(() => import('./components/Alianzas'));
const FAQ = lazy(() => import('./components/FAQ'));
const Counseling = lazy(() => import('./components/Counseling'));
const Footer = lazy(() => import('./components/Footer'));

// const Benefits = lazy(() => import('./components/Benefits'));
// const Header = lazy(() => import('./components/Header'));
// const Sliders = lazy(() => import('./components/Sliders'));
// const Trainings = lazy(() => import('./components/Trainings'));
// const Masters = lazy(() => import('./components/Masters'));
// const Courses = lazy(() => import('./components/Courses'));
// const Convert3DPro = lazy(() => import('./components/Convert3DPro'));
// const Memberships = lazy(() => import('./components/Memberships'));
// const FeaturedResults = lazy(() => import('./components/FeaturedResults'));
// const Financing = lazy(() => import('./components/Financing'));

function App() {
	const [close, setClose] = useState(false);

	const handlerClose = () => {
		close ? setClose(false) : setClose(true);
	};

	const handleCloseModal = (event) => {
		if (event.target.className === 'Inscriptions ') {
			setClose(true);
		}
	};

	return (
		<main className='App'>
			<Suspense
				fallback={
					<div
						style={{
							background: 'black',
							width: '100%',
							height: '100vh',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<div className='spinner'></div>
					</div>
				}
			>
				{/* <SimpleModal
					close={close}
					handlerClose={handlerClose}
					handleCloseModal={handleCloseModal}
				/> */}
				<Navbar />
				<HeaderForm />
				<SwipersHome />
				<Especialistas />
				<MastersHome />
				{/* <ResultadosAlumnos /> */}
				<Gallery />
				{/* <Certificacion /> */}
				<QuienesSomos />
				{/* <BenefitsHome /> */}
				{/* <DocentesHome /> */}
				<Alinazas />
				<FAQ />
				<Counseling />
				<Footer />
				{/* <Benefits /> */}
				{/* <Masters /> */}
				{/* <Header /> */}
				{/* <Sliders	 /> */}
				{/* <Courses /> */}
				{/* <Convert3DPro /> */}
			</Suspense>
		</main>
	);
}

export default App;
